import React from 'react'
import '../../assets/css/pages/equipe.css'

function Equipe() {
  return (
    <>
      <div className='equipe-section1'>Equipe</div>
      <div className='equipe-section2'>Equipe</div>
    </>
  )
}

export default Equipe
