import React from 'react'
import '../../assets/css/components/article.css'

function DernierArticle({ data }) {
  const lastArticle = data[0]
  return (
    <>
      {lastArticle ? (
        <div>
          <h1 className='article-title'>{lastArticle.name}</h1>
          <div id='article'></div>
          <div
            id='content'
            className='article-content'
            dangerouslySetInnerHTML={{ __html: lastArticle.content }}
          ></div>
        </div>
      ) : (
        <div>Chargement de l'article...</div>
      )}
    </>
  )
}

export default DernierArticle
