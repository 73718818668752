import React from 'react'
import { Outlet } from 'react-router-dom'
import '../../assets/css/pages/actualites.css'
import DerniersArticles from '../../components/DerniersArticles'

function Actualites({ data }) {
  return (
    <>
      <div className='actualites-section1'></div>
      <div className='actualites-section2'>
        <DerniersArticles articles={data} />
      </div>
      <div className='actualites-section3'>
        <Outlet />
      </div>
    </>
  )
}

export default Actualites
