import React from 'react'
import '../../assets/css/pages/contact.css'
import axios from 'axios'

class Contact extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      message: '',
      phone: '',
      email: '',
      name: '',
      errora: ['Tous les champs sont obligatoires.'],
    }
  }

  handleSubmit(e) {
    e.preventDefault()

    axios({
      method: 'POST',
      url: 'https://www.mlgweb.fr/send/index.php',
      data: this.state,
    }).then((response) => {
      const erMessage = document.getElementById('message-er')
      const erName = document.getElementById('name-er')
      const erPhone = document.getElementById('phone-er')
      const erEmail = document.getElementById('email-er')
      if (response.data.status === 'success') {
        alert('Message envoyé.')
        this.resetForm()
        erMessage.style.display = 'none'
        erName.style.display = 'none'
        erPhone.style.display = 'none'
        erEmail.style.display = 'none'
      } else if (response.data.status === 'fail') {
        this.setState({ errora: response.data.error })
        this.state.errora.message
          ? (erMessage.style.display = 'block')
          : (erMessage.style.display = 'none')
        this.state.errora.name
          ? (erName.style.display = 'block')
          : (erName.style.display = 'none')
        this.state.errora.phone
          ? (erPhone.style.display = 'block')
          : (erPhone.style.display = 'none')
        this.state.errora.email
          ? (erEmail.style.display = 'block')
          : (erEmail.style.display = 'none')
      }
    })
  }
  resetForm() {
    this.setState({
      message: '',
      phone: '',
      email: '',
      name: '',
      errora: ['Tous les champs sont obligatoires.'],
    })
  }
  render() {
    return (
      <>
        <div className='contact-section1'>
          <h1>Contact</h1>
        </div>
        <div className='contact-section2'>
          <div className='section-title'>
            <h2>Contactez nous</h2>
          </div>
          <div className='section-content'>
            <div className='form-container'>
              <form
                id='contact-form'
                onSubmit={this.handleSubmit.bind(this)}
                method='POST'
                autoComplete='off'
              >
                <div className='input-container'>
                  <fieldset className='ui-input'>
                    <span className='contact-form-error' id='message-er'>
                      {this.state.errora.message}
                    </span>
                    <input
                      type='text'
                      id='message'
                      value={this.state.message}
                      onChange={this.onMessageChange.bind(this)}
                    />
                    <label htmlFor='message'>
                      <span>Vos nom et prénom</span>
                    </label>
                  </fieldset>
                </div>
                <div className='input-container'>
                  <fieldset className='ui-input'>
                    <span className='contact-form-error' id='phone-er'>
                      {this.state.errora.phone}
                    </span>
                    <input
                      type='text'
                      id='phone'
                      value={this.state.phone}
                      onChange={this.onPhoneChange.bind(this)}
                    />
                    <label htmlFor='phone'>
                      <span>Votre numéro de téléphone</span>
                    </label>
                  </fieldset>
                </div>
                <div className='input-container'>
                  <fieldset className='ui-input'>
                    <span className='contact-form-error' id='email-er'>
                      {this.state.errora.email}
                    </span>
                    <input
                      /* type='email' */
                      id='email'
                      /* aria-describedby='emailHelp' */
                      value={this.state.email}
                      onChange={this.onEmailChange.bind(this)}
                    />
                    <label htmlFor='email'>
                      <span>Votre adresse email</span>
                    </label>
                  </fieldset>
                </div>
                <div className='textarea-container'>
                  <fieldset className='ui-input'>
                    <span className='contact-form-error' id='name-er'>
                      {this.state.errora.name}
                    </span>
                    <textarea
                      rows='8'
                      id='name'
                      value={this.state.name}
                      onChange={this.onNameChange.bind(this)}
                    />
                    <label htmlFor='name'>
                      <span>Votre message</span>
                    </label>
                  </fieldset>
                </div>
                <div className='cbox-container'>
                  <fieldset className='ui-cbox'>
                    <div className='cbox-box'>
                      <input
                        type='checkbox'
                        className='checkbox'
                        required
                        id='rgpd'
                      />
                    </div>

                    <div className='rgpd'>
                      Message RGPD cfzerf zef zefz efz efz efz efz efze fze fzef
                      zef zef zef zef ff f fffffffff f f f fffffffff f f f
                      ergergergerg gergergerger gerergergerg gerger ergergerg
                      ergergergrgrger grgrgerrg ergreg rger
                    </div>
                  </fieldset>
                </div>
                <br />
                <input type='submit' name='submit' value='Envoyer' />
                <br />
              </form>
            </div>
          </div>
        </div>
      </>
    )
  }
  onNameChange(event) {
    this.setState({ name: event.target.value })
  }
  onPhoneChange(event) {
    this.setState({ phone: event.target.value })
  }

  onEmailChange(event) {
    this.setState({ email: event.target.value })
  }

  onMessageChange(event) {
    this.setState({ message: event.target.value })
  }
}
export default Contact
