import { useRouteError, Link } from 'react-router-dom'
import '../../assets/css/pages/errorpage.css'
import logo from '../../assets/img/logo_error.svg'

export default function ErrorPage() {
  const error = useRouteError()
  console.error(error)

  return (
    <div id='error-page' className='error-page'>
      <div className='error-container'>
        <object type='image/svg+xml' data={logo} className='error-logo'>
          Logo PLA
        </object>
        <h1>Oops!</h1>
        <p>
          Une Erreur imprévue est apparue :{' '}
          <i>{error.statusText || error.message}</i>
        </p>
        <p>
          <Link to='/' className='error-link'>
            Revenir à l'accueil
          </Link>
        </p>
      </div>
    </div>
  )
}
