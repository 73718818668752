import { GET_ARTICLES_FULL } from '../constants/actionTypes'
const articleReducer = (state = [], action) => {
  switch (action.type) {
    case GET_ARTICLES_FULL:
      return action.payload
    default:
      return state
  }
}
export default articleReducer
