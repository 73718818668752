import React from 'react'
import '../../assets/css/layout/footer.css'

function Footer() {
  return (
    <div className='footer'>
      <span className='mentions-legales'>
        Mentions légales | Politique de confidentialité
      </span>
      <span className='credits'>
        Site crée par MLgweb.fr et Clémentine Arramon-Tucoo.
      </span>
    </div>
  )
}

export default Footer
