import React from 'react'
import Navigation from './Navigation'
import Hamburger from './Hamburger'

function Header() {
  return (
    <div>
      <Hamburger />
      <Navigation />
    </div>
  )
}

export default Header
