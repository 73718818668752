import * as api from '../api'
import { GET_ARTICLES_FULL } from '../constants/actionTypes'

export const getArticles = () => async (dispatch) => {
  try {
    const listData = await api.getArticlesList()
    const data = await Promise.all(
      listData.data.map(async (element) => {
        const test = await api.getArticle(element)
        return test.data
      })
    )
    dispatch({ type: GET_ARTICLES_FULL, payload: data })
  } catch (error) {
    console.log(error.message)
  }
}
