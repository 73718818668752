import React from 'react'
import '../../assets/css/components/derniersarticles.css'
import CardArticle from '../CardArticle/CardArticle'
/* import Slide from '../../assets/img/slide_button.svg' */

function DerniersArticles({ articles }) {
  //ajout de la fonction qui permet de scroller avedc la molette
  /* useEffect(() => {
    const mouseWheel = document.querySelector('.articles-list')
    mouseWheel.addEventListener('wheel', function (e) {
      const race = 5 // How many pixels to scroll

      if (e.deltaY > 0)
        // Scroll right
        mouseWheel.scrollLeft += race
      // Scroll left
      else mouseWheel.scrollLeft -= race
      e.preventDefault()
    })
  }, []) */
  /*   console.log(articles)
  articles = Array.from(articles) */
  let offset = 282
  if (window.innerWidth > 500) {
    offset = 353
  }
  if (window.innerWidth < 371) {
    offset = 256
  }
  const slide = (d, o, i) => {
    d === 'left'
      ? document.getElementById(i).scrollBy(-o.offset, 0)
      : document.getElementById(i).scrollBy(o.offset, 0)
  }
  console.log(offset)
  return (
    <div className='articles-list-container'>
      <div className='articles-list-title'>Derniers articles</div>
      <div className='articles-list-left'>
        <svg
          xmlns='http://www.w3.org/2000/svg'
          viewBox='0 -27 2.5 32'
          className='slide-left'
          onClick={() => {
            slide('left', { offset }, 'articles-list')
          }}
        >
          <path d='M 0 -27 V 5 H 1 C 3 -7 3 -15 1 -27' strokeWidth='0.1' />
        </svg>
      </div>
      <div className='articles-list-right'>
        <svg
          xmlns='http://www.w3.org/2000/svg'
          viewBox='0 -27 2.5 32'
          className='slide-right'
          onClick={() => {
            slide('right', { offset }, 'articles-list')
          }}
        >
          <path d='M 0 -27 V 5 H 1 C 3 -7 3 -15 1 -27' strokeWidth='0.1' />
        </svg>
      </div>
      <div className='articles-list' id='articles-list'>
        {articles.map((article) => {
          return (
            <div className='card-container' key={article.id}>
              <CardArticle article={article} />
            </div>
          )
        })}
      </div>
    </div>
  )
}

export default DerniersArticles
