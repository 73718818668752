import React, { useEffect, useState } from 'react'
import { useParams } from 'react-router-dom'
import '../../assets/css/components/article.css'

function Article({ data }) {
  const { id } = useParams()
  const [article, setArticle] = useState([])
  useEffect(() => {
    const filterArticle = data.filter((obj) => obj.id === id)

    setArticle(filterArticle)
  }, [data, id])
  return (
    <>
      {article && article.length > 0 ? (
        <div className='article-container'>
          <div id='article'></div>
          <h1 className='article-title'>{article[0].name}</h1>
          <div
            id='content'
            className='article-content'
            dangerouslySetInnerHTML={{ __html: article[0].content }}
          ></div>
        </div>
      ) : (
        <div>Chargement de l'article</div>
      )}
    </>
  )
}

export default Article
