import React, { useEffect } from 'react'
import { Routes, Route, useLocation } from 'react-router-dom'
import { AnimatePresence } from 'framer-motion'
import Layout from '../../Layout'
import Homepage from '../../pages/Homepage'
import Cabinet from '../../pages/Cabinet'
import Equipe from '../../pages/Equipe'
import Expertises from '../../pages/Expertises'
import Actualites from '../../pages/Actualites'
import Contact from '../../pages/Contact'
import ErrorPage from '../../pages/ErrorPage'
import Article from '../Article'
import DernierArticle from '../DernierArticle'
import { getArticles } from '../../actions/articles'
import { useDispatch, useSelector } from 'react-redux'

function AnimatedRoutes() {
  /*   const [data, setData] = useState(null) */
  const location = useLocation()
  const locationArr = location.pathname?.split('/') ?? []
  const dispatch = useDispatch()
  useEffect(() => {
    dispatch(getArticles())
  }, [dispatch])
  const data = useSelector((state) => state.articles)
  return (
    <AnimatePresence mode='wait'>
      <Routes location={location} key={locationArr[1]}>
        <Route path='/' element={<Layout />} errorElement={<ErrorPage />}>
          <Route path='' element={<Homepage />} index />
          <Route path='le-cabinet' element={<Cabinet />} />
          <Route path='l-equipe' element={<Equipe />} />
          <Route path='nos-expertises' element={<Expertises />} />
          <Route
            path='actualites/'
            element={<Actualites data={data} />}
            key={locationArr[2]}
          >
            <Route index element={<DernierArticle data={data} />} />
            <Route
              path=':id/:articleName'
              element={<Article data={data} />}
              loader={data}
            />
          </Route>
          <Route path='contact' element={<Contact />} />
        </Route>
      </Routes>
    </AnimatePresence>
  )
}

export default AnimatedRoutes
