import React, { useEffect } from 'react'
import '../../../assets/css/layout/logo.css'

function Logo() {
  useEffect(() => {
    const headerLogo = document.querySelector('.header-logo')
    let previousScrollY = 0

    const handleScroll = () => {
      if (
        window.scrollY > 70 &&
        previousScrollY <= window.scrollY &&
        window.innerWidth < 1000
      ) {
        headerLogo.classList.add('bg-color')
        headerLogo.classList.remove('bg-color-start')
      } else if (
        window.scrollY <= 70 &&
        previousScrollY > window.scrollY &&
        window.innerWidth < 1000
      ) {
        headerLogo.classList.remove('bg-color')
        headerLogo.classList.add('bg-color-start')
      }
      previousScrollY = window.scrollY
    }

    window.addEventListener('scroll', handleScroll)

    return () => {
      window.removeEventListener('scroll', handleScroll)
    }
  }, [])

  /* const visible = { x: 0, transition: { duration: 2.0 } } */
  return (
    <div className='header-logo bg-color-start'>
      <svg
        xmlns='http://www.w3.org/2000/svg'
        viewBox='0 0 188.9 51.09'
        className='logo-header'
      >
        <path
          className='logo-header-path'
          d='M100.46,50,78.17.78C78,.12,77.62,0,77.18.1a.76.76,0,0,0-.9.5l-22,48.58h-19V1.09a.83.83,0,0,0-1-.95c-.68,0-1,.3-1,1v9.18A15.91,15.91,0,0,0,18.52.13H1a1.14,1.14,0,0,0-1,1.1.92.92,0,0,0,.34.82L12.9,29.76H1.3a1,1,0,0,0-1,1.1V50.13a.84.84,0,0,0,1,1,.84.84,0,0,0,1-1V31.75H18.52A15.92,15.92,0,0,0,33.3,21.65V50.13a1,1,0,0,0,.2.69c.1.17.38.27.82.27H54.8a.84.84,0,0,0,.76-.43l0-.1L59,43.36,77.18,3.42,98.06,49.14H66.55a.92.92,0,0,0-.88,1,1,1,0,0,0,.78,1H99.94C100.67,51.09,100.77,50.61,100.46,50ZM19.69,29.76H15.17L2.67,2.05H18.52A13.67,13.67,0,0,1,32.3,16,13.57,13.57,0,0,1,19.69,29.76Z'
        />
        <path
          className='logo-header-path'
          d='M112.33,9.76V.08H116a5,5,0,0,1,2.11.4,3,3,0,0,1,1.36,1.13,3.21,3.21,0,0,1,.48,1.79,3.15,3.15,0,0,1-.48,1.76,3.09,3.09,0,0,1-1.36,1.15,5.19,5.19,0,0,1-2.11.4H112.9l.46-.49V9.76Zm1-3.45-.46-.5h3a3.36,3.36,0,0,0,2.2-.63,2.2,2.2,0,0,0,.76-1.78,2.25,2.25,0,0,0-.76-1.8,3.36,3.36,0,0,0-2.2-.63h-3l.46-.49Z'
        />
        <path
          className='logo-header-path'
          d='M123.34,8.88h5.82v.88h-6.85V.08H129V1h-5.61Zm-.11-4.47h5.11v.87h-5.11Z'
        />
        <path
          className='logo-header-path'
          d='M135.93,9.84a5.48,5.48,0,0,1-2-.36,4.88,4.88,0,0,1-1.61-1,5,5,0,0,1-1.06-1.56,5.09,5.09,0,0,1-.38-2,5,5,0,0,1,.38-2,4.72,4.72,0,0,1,1.07-1.56,4.68,4.68,0,0,1,1.61-1A5.34,5.34,0,0,1,136,0a5.65,5.65,0,0,1,2,.35,4.12,4.12,0,0,1,1.56,1.06l-.65.65a3.6,3.6,0,0,0-1.32-.88,4.53,4.53,0,0,0-3.16,0,3.93,3.93,0,0,0-1.3.84,4,4,0,0,0-.87,1.28,4.1,4.1,0,0,0-.31,1.59,4.15,4.15,0,0,0,.31,1.6,4,4,0,0,0,.87,1.27,3.64,3.64,0,0,0,1.3.84,4.53,4.53,0,0,0,3.16,0,3.76,3.76,0,0,0,1.32-.89l.65.65a4.15,4.15,0,0,1-1.56,1.07A5.5,5.5,0,0,1,135.93,9.84Z'
        />
        <path
          className='logo-header-path'
          d='M140.07,9.76,144.49.08h1l4.43,9.68h-1.08L144.78.68h.42l-4.07,9.08Zm1.74-2.58.31-.83h5.62l.31.83Z'
        />
        <path
          className='logo-header-path'
          d='M154.39,9.84a6,6,0,0,1-2.06-.35,3.88,3.88,0,0,1-1.53-.9l.4-.79a4,4,0,0,0,1.38.83,4.81,4.81,0,0,0,1.81.34,4.15,4.15,0,0,0,1.49-.22,1.85,1.85,0,0,0,.84-.62,1.46,1.46,0,0,0,.27-.86,1.31,1.31,0,0,0-.32-.91,2.36,2.36,0,0,0-.85-.55,10,10,0,0,0-1.16-.35l-1.28-.32a5.22,5.22,0,0,1-1.16-.47,2.44,2.44,0,0,1-.86-.76A2.18,2.18,0,0,1,151,2.65a2.5,2.5,0,0,1,.38-1.32,2.69,2.69,0,0,1,1.17-1,4.74,4.74,0,0,1,2-.37,6,6,0,0,1,1.62.23,4.61,4.61,0,0,1,1.38.63l-.34.81a4.93,4.93,0,0,0-1.32-.61,5.49,5.49,0,0,0-1.35-.19,3.73,3.73,0,0,0-1.44.24,1.74,1.74,0,0,0-.84.63,1.51,1.51,0,0,0-.27.89,1.27,1.27,0,0,0,.33.91,2.11,2.11,0,0,0,.86.54,10,10,0,0,0,1.16.34l1.27.34a5.73,5.73,0,0,1,1.16.46,2.41,2.41,0,0,1,.86.75A2.2,2.2,0,0,1,158,7.2a2.44,2.44,0,0,1-.38,1.31,2.78,2.78,0,0,1-1.19,1A5,5,0,0,1,154.39,9.84Z'
        />
        <path
          className='logo-header-path'
          d='M163.09,9.84A6,6,0,0,1,161,9.49a3.83,3.83,0,0,1-1.52-.9l.4-.79a4,4,0,0,0,1.37.83,4.9,4.9,0,0,0,1.82.34,4.18,4.18,0,0,0,1.49-.22,1.85,1.85,0,0,0,.84-.62,1.46,1.46,0,0,0,.27-.86,1.31,1.31,0,0,0-.32-.91,2.36,2.36,0,0,0-.85-.55,10.18,10.18,0,0,0-1.17-.35l-1.27-.32a5.5,5.5,0,0,1-1.17-.47,2.4,2.4,0,0,1-.85-.76,2.18,2.18,0,0,1-.33-1.26,2.5,2.5,0,0,1,.38-1.32,2.58,2.58,0,0,1,1.17-1,4.74,4.74,0,0,1,2-.37,6,6,0,0,1,1.62.23,4.61,4.61,0,0,1,1.38.63l-.35.81a4.66,4.66,0,0,0-1.32-.61,5.36,5.36,0,0,0-1.35-.19,3.64,3.64,0,0,0-1.43.24,1.74,1.74,0,0,0-.84.63,1.51,1.51,0,0,0-.27.89,1.27,1.27,0,0,0,.33.91,2.14,2.14,0,0,0,.85.54,10.86,10.86,0,0,0,1.17.34l1.27.34a6,6,0,0,1,1.16.46,2.38,2.38,0,0,1,.85.75,2.13,2.13,0,0,1,.33,1.23,2.36,2.36,0,0,1-.39,1.31,2.75,2.75,0,0,1-1.18,1A5,5,0,0,1,163.09,9.84Z'
        />
        <path
          className='logo-header-path'
          d='M173.35,9.84a5.63,5.63,0,0,1-2-.36,4.75,4.75,0,0,1-1.61-1,4.93,4.93,0,0,1-1.07-1.56,5,5,0,0,1-.38-2A5,5,0,0,1,168.64,3a4.86,4.86,0,0,1,1.07-1.57,4.73,4.73,0,0,1,1.61-1,5.73,5.73,0,0,1,4.05,0,4.68,4.68,0,0,1,1.61,1A4.55,4.55,0,0,1,178,3a4.86,4.86,0,0,1,.38,2,4.91,4.91,0,0,1-.38,2A4.64,4.64,0,0,1,177,8.45a5,5,0,0,1-1.61,1A5.54,5.54,0,0,1,173.35,9.84Zm0-.91a4.23,4.23,0,0,0,1.61-.3,3.69,3.69,0,0,0,1.29-.84,4.1,4.1,0,0,0,.85-1.27,4.15,4.15,0,0,0,.3-1.6,4.09,4.09,0,0,0-.3-1.59,4.15,4.15,0,0,0-.85-1.28A4,4,0,0,0,175,1.21a4.23,4.23,0,0,0-1.61-.3,4.32,4.32,0,0,0-1.62.3,3.93,3.93,0,0,0-1.3.84,3.82,3.82,0,0,0-.85,1.28,3.92,3.92,0,0,0-.31,1.59,4,4,0,0,0,.31,1.59,3.73,3.73,0,0,0,.85,1.28,3.64,3.64,0,0,0,1.3.84A4.32,4.32,0,0,0,173.35,8.93Z'
        />
        <path
          className='logo-header-path'
          d='M184.81,9.84a3.91,3.91,0,0,1-2.91-1.06,4.27,4.27,0,0,1-1.07-3.15V.08h1V5.59a3.57,3.57,0,0,0,.78,2.53,2.86,2.86,0,0,0,2.18.81,2.89,2.89,0,0,0,2.2-.81,3.57,3.57,0,0,0,.78-2.53V.08h1V5.63a4.27,4.27,0,0,1-1.06,3.15A3.9,3.9,0,0,1,184.81,9.84Z'
        />
        <path
          className='logo-header-path'
          d='M112.1,30.39V20.66h1V29.5h5.45v.89Z'
        />
        <path
          className='logo-header-path'
          d='M127.12,30.47a5.48,5.48,0,0,1-2-.37,4.79,4.79,0,0,1-2.69-2.61,5.27,5.27,0,0,1,0-3.93A4.79,4.79,0,0,1,125.08,21a5.78,5.78,0,0,1,4.07,0,4.84,4.84,0,0,1,3.07,4.57,5,5,0,0,1-.39,2,4.68,4.68,0,0,1-2.68,2.6A5.39,5.39,0,0,1,127.12,30.47Zm0-.92a4.32,4.32,0,0,0,1.62-.3,4,4,0,0,0,1.29-.84,4.09,4.09,0,0,0,.85-1.28,4.31,4.31,0,0,0,0-3.21,4,4,0,0,0-.85-1.28,4,4,0,0,0-1.29-.85,4.52,4.52,0,0,0-1.62-.29,4.58,4.58,0,0,0-1.63.29,4,4,0,0,0-1.3.85,3.85,3.85,0,0,0-.86,1.28,4.28,4.28,0,0,0,0,3.2,3.89,3.89,0,0,0,.86,1.29,3.93,3.93,0,0,0,1.3.84A4.37,4.37,0,0,0,127.12,29.55Z'
        />
        <path
          className='logo-header-path'
          d='M141.56,30.47a5.4,5.4,0,0,1-2-.37,4.82,4.82,0,0,1-1.63-1,4.86,4.86,0,0,1-1.07-1.57,5.32,5.32,0,0,1,0-3.95A4.74,4.74,0,0,1,137.9,22a4.82,4.82,0,0,1,1.63-1,5.46,5.46,0,0,1,2.05-.37,5.73,5.73,0,0,1,2,.35A4.06,4.06,0,0,1,145.19,22l-.64.66a3.7,3.7,0,0,0-1.35-.88,4.56,4.56,0,0,0-1.58-.26,4.67,4.67,0,0,0-1.66.29,4,4,0,0,0-1.32.85,3.88,3.88,0,0,0-.87,1.28,4.16,4.16,0,0,0,0,3.2,3.92,3.92,0,0,0,.87,1.29,4,4,0,0,0,1.32.84,4.4,4.4,0,0,0,1.65.3,4.78,4.78,0,0,0,1.57-.25,3.56,3.56,0,0,0,1.37-.85l.58.78a4.64,4.64,0,0,1-1.62.93A6.32,6.32,0,0,1,141.56,30.47Zm2.59-1.36V25.52h1v3.71Z'
        />
        <path
          className='logo-header-path'
          d='M151.84,29.5h5.85v.89h-6.88V20.66h6.67v.89h-5.64ZM151.73,25h5.14v.88h-5.14Z'
        />
        <path
          className='logo-header-path'
          d='M161.33,30.39l4.44-9.73h1l4.44,9.73h-1.08l-4.09-9.13h.42l-4.08,9.13Zm1.75-2.6.3-.84H169l.3.84Z'
        />
        <path className='logo-header-path' d='M175.62,30.39V20.66h1v9.73Z' />
        <path
          className='logo-header-path'
          d='M185.27,30.47a6,6,0,0,1-2.07-.36,3.75,3.75,0,0,1-1.54-.91l.4-.79a4,4,0,0,0,1.39.84,4.86,4.86,0,0,0,1.82.34,4,4,0,0,0,1.5-.23,1.77,1.77,0,0,0,.84-.61,1.49,1.49,0,0,0,.27-.86,1.28,1.28,0,0,0-.32-.92,2.2,2.2,0,0,0-.86-.55,10,10,0,0,0-1.16-.35l-1.28-.33a5.11,5.11,0,0,1-1.17-.47,2.4,2.4,0,0,1-.87-.77,2.25,2.25,0,0,1-.32-1.25,2.46,2.46,0,0,1,.38-1.33,2.65,2.65,0,0,1,1.17-1,4.78,4.78,0,0,1,2-.37,6.05,6.05,0,0,1,1.63.23,4.67,4.67,0,0,1,1.39.63l-.35.82a4.57,4.57,0,0,0-1.33-.61,5.08,5.08,0,0,0-1.35-.2,3.75,3.75,0,0,0-1.45.24,1.87,1.87,0,0,0-.84.63,1.59,1.59,0,0,0-.27.9,1.3,1.3,0,0,0,.33.92,2.18,2.18,0,0,0,.86.54,10.86,10.86,0,0,0,1.17.34l1.27.34a5.82,5.82,0,0,1,1.17.46,2.44,2.44,0,0,1,.86.76,2.12,2.12,0,0,1,.33,1.24,2.36,2.36,0,0,1-.39,1.31,2.71,2.71,0,0,1-1.19,1A4.82,4.82,0,0,1,185.27,30.47Z'
        />
        <path
          className='logo-header-path'
          d='M110.52,50.82,115,41.11h1l4.44,9.71h-1.08l-4.08-9.12h.42l-4.08,9.12Zm1.75-2.6.3-.83h5.65l.3.83Z'
        />
        <path
          className='logo-header-path'
          d='M126.46,50.82l-4.3-9.71h1.11l4,9.14h-.61l4-9.14h1l-4.3,9.71Z'
        />
        <path
          className='logo-header-path'
          d='M139.51,50.9a5.39,5.39,0,0,1-2-.37,4.69,4.69,0,0,1-1.63-1,5,5,0,0,1,0-7.06,4.9,4.9,0,0,1,1.62-1,5.61,5.61,0,0,1,2-.37,5.54,5.54,0,0,1,2,.37,5,5,0,0,1,1.62,1A4.86,4.86,0,0,1,144.22,44a5.3,5.3,0,0,1,0,3.94,4.73,4.73,0,0,1-2.69,2.6A5.34,5.34,0,0,1,139.51,50.9Zm0-.92a4.52,4.52,0,0,0,1.62-.29,4,4,0,0,0,1.29-.85,4.12,4.12,0,0,0,0-5.76,4,4,0,0,0-1.29-.84,4.52,4.52,0,0,0-3.24,0,3.81,3.81,0,0,0-2.17,2.12,4.15,4.15,0,0,0-.3,1.6,4.22,4.22,0,0,0,.3,1.6,3.87,3.87,0,0,0,2.17,2.13A4.52,4.52,0,0,0,139.51,50Z'
        />
        <path
          className='logo-header-path'
          d='M153.3,50.9a5.39,5.39,0,0,1-2-.37,4.83,4.83,0,0,1-1.61-1,5,5,0,0,1-1.06-1.57,5.3,5.3,0,0,1,0-3.94,4.86,4.86,0,0,1,1.07-1.57,5,5,0,0,1,1.62-1,5.5,5.5,0,0,1,2-.37,5.44,5.44,0,0,1,2,.36,3.91,3.91,0,0,1,1.57,1.06l-.65.65a3.6,3.6,0,0,0-1.32-.88,4.58,4.58,0,0,0-3.18,0,3.89,3.89,0,0,0-2.17,2.12,4.28,4.28,0,0,0,0,3.2,4,4,0,0,0,2.17,2.13,4.58,4.58,0,0,0,1.63.29,4.39,4.39,0,0,0,1.55-.27,3.76,3.76,0,0,0,1.32-.89l.65.65a4,4,0,0,1-1.57,1.07A5.45,5.45,0,0,1,153.3,50.9Z'
        />
        <path
          className='logo-header-path'
          d='M159.43,50.82l4.44-9.71h1l4.44,9.71h-1.09l-4.07-9.12h.41l-4.08,9.12Zm1.74-2.6.31-.83h5.64l.31.83Z'
        />
        <path
          className='logo-header-path'
          d='M174.5,50.82V42h-3.41v-.88h7.85V42h-3.41v8.83Z'
        />
        <path
          className='logo-header-path'
          d='M185.28,50.9a6.25,6.25,0,0,1-2.07-.35,4.14,4.14,0,0,1-1.54-.91l.41-.79a4.16,4.16,0,0,0,1.38.84,5,5,0,0,0,1.82.34,4.13,4.13,0,0,0,1.49-.23,1.83,1.83,0,0,0,.85-.62,1.49,1.49,0,0,0,.27-.86,1.3,1.3,0,0,0-.33-.92,2.22,2.22,0,0,0-.85-.54,8.09,8.09,0,0,0-1.17-.36l-1.27-.32a5.11,5.11,0,0,1-1.17-.47,2.29,2.29,0,0,1-.86-.77,2.17,2.17,0,0,1-.33-1.25,2.48,2.48,0,0,1,.38-1.33,2.78,2.78,0,0,1,1.17-1,4.94,4.94,0,0,1,2-.37,6,6,0,0,1,1.62.23,5.09,5.09,0,0,1,1.39.63l-.35.82a4.51,4.51,0,0,0-1.32-.61,5.06,5.06,0,0,0-1.36-.19,4,4,0,0,0-1.44.23,1.87,1.87,0,0,0-.84.63,1.59,1.59,0,0,0-.27.9,1.26,1.26,0,0,0,.33.91,2.09,2.09,0,0,0,.86.55,9.67,9.67,0,0,0,1.17.34c.43.1.85.21,1.27.33a6.39,6.39,0,0,1,1.16.47,2.27,2.27,0,0,1,.86.76,2.08,2.08,0,0,1,.33,1.23,2.36,2.36,0,0,1-.39,1.31,2.68,2.68,0,0,1-1.18,1A4.88,4.88,0,0,1,185.28,50.9Z'
        />
      </svg>
    </div>
  )
}

export default Logo
