import React from 'react'
import '../../assets/css/pages/homepage.css'
import { motion } from 'framer-motion'

function Homepage() {
  const draw = {
    hidden: { pathLength: 0, opacity: 0 },
    visible: (i) => {
      const delay = 1 + i * 0.5
      return {
        pathLength: 1,
        opacity: 1,
        transition: {
          pathLength: { delay, type: 'spring', duration: 4, bounce: 0 },
          opacity: { delay, duration: 0.01 },
        },
      }
    },
  }
  const appear = {
    hidden: { opacity: 0 },
    visible: (i) => {
      const delay = 1 + i * 0.5
      return {
        opacity: 1,
        transition: {
          opacity: { delay, duration: 2 },
        },
      }
    },
  }
  return (
    <>
      <div className='home-wrapper'>
        <motion.svg
          xmlns='http://www.w3.org/2000/svg'
          viewBox='0 0 678.23 501.12'
          className='home-logo'
          initial='hidden'
          animate='visible'
        >
          <rect className='svg-bg' width='678.23' height='501.12' />
          <motion.path
            className='svg-text'
            d='M430.07,339.66v.06h.53A1.63,1.63,0,0,1,430.07,339.66Z'
            variants={appear}
          />
          <motion.path
            className='svg-text'
            d='M95,445.73H68.56L62.87,458.4H57.64l21.72-47.47h4.93L106,458.4h-5.27Zm-1.87-4.08-11.39-25.5-11.39,25.5Z'
            variants={appear}
            custom={10}
          />
          <motion.path
            className='svg-text'
            d='M192.92,410.93l-21,47.47H167l-21-47.47h5.39l18.19,41.22,18.32-41.22Z'
            variants={appear}
            custom={10}
          />
          <motion.path
            className='svg-text'
            d='M261.08,458.82a26.09,26.09,0,0,1-12.79-3.14,23.4,23.4,0,0,1-8.93-8.67,25.21,25.21,0,0,1,0-24.69,23,23,0,0,1,8.93-8.63,27.21,27.21,0,0,1,25.49,0,22.85,22.85,0,0,1,8.93,8.62,25.11,25.11,0,0,1,0,24.78,23,23,0,0,1-8.93,8.67A26,26,0,0,1,261.08,458.82Zm0-4.5a20.72,20.72,0,0,0,10.2-2.51,18.23,18.23,0,0,0,7.09-7.06,20,20,0,0,0,2.6-10.11,19.76,19.76,0,0,0-2.6-10,17.94,17.94,0,0,0-7.09-7.05,21.68,21.68,0,0,0-20.4,0,18.49,18.49,0,0,0-7.23,7.05,19.75,19.75,0,0,0-2.59,10,20,20,0,0,0,2.59,10.11,18.79,18.79,0,0,0,7.23,7.06A20.83,20.83,0,0,0,261.08,454.32Z'
            variants={appear}
            custom={10}
          />
          <motion.path
            className='svg-text'
            d='M357.41,458.82a25.91,25.91,0,0,1-12.7-3.1,22.91,22.91,0,0,1-8.88-8.67,25.38,25.38,0,0,1,0-24.78,22.69,22.69,0,0,1,8.92-8.62,27.36,27.36,0,0,1,22.48-1.36,20,20,0,0,1,7.61,5.14l-3.19,3.19a19,19,0,0,0-14-5.61,20.78,20.78,0,0,0-10.29,2.59,18.81,18.81,0,0,0-7.22,7,19.53,19.53,0,0,0-2.59,10,19.74,19.74,0,0,0,2.59,10.07,19,19,0,0,0,7.22,7.06,21,21,0,0,0,10.29,2.55,18.56,18.56,0,0,0,14-5.7l3.19,3.19a20.56,20.56,0,0,1-7.61,5.23A26.42,26.42,0,0,1,357.41,458.82Z'
            variants={appear}
            custom={10}
          />
          <motion.path
            className='svg-text'
            d='M454.92,445.73H428.44l-5.69,12.67h-5.23l21.72-47.47h4.93l21.71,47.47h-5.27Zm-1.87-4.08-11.39-25.5-11.39,25.5Z'
            variants={appear}
            custom={10}
          />
          <motion.path
            className='svg-text'
            d='M522.54,415.26h-16.7v-4.33h38.42v4.33H527.55V458.4h-5Z'
            variants={appear}
            custom={10}
          />
          <motion.path
            className='svg-text'
            d='M604.9,458.82a30.27,30.27,0,0,1-10.16-1.7,18.23,18.23,0,0,1-7.48-4.5l2-3.87a19,19,0,0,0,6.84,4.21,25.42,25.42,0,0,0,8.84,1.57c4.25,0,7.43-.78,9.56-2.34a7.21,7.21,0,0,0,3.19-6.08,6.06,6.06,0,0,0-1.75-4.54,11.05,11.05,0,0,0-4.2-2.59,63.84,63.84,0,0,0-7-2,79.24,79.24,0,0,1-8.55-2.59,14.36,14.36,0,0,1-5.48-3.87,10.2,10.2,0,0,1-2.25-7,11.81,11.81,0,0,1,1.91-6.55,13,13,0,0,1,5.82-4.71,23.74,23.74,0,0,1,9.78-1.79,27.58,27.58,0,0,1,7.94,1.15,23,23,0,0,1,6.76,3.06l-1.7,4a24.19,24.19,0,0,0-13-3.91q-6.25,0-9.39,2.43a7.36,7.36,0,0,0-3.15,6.16,6.26,6.26,0,0,0,1.7,4.59,11.36,11.36,0,0,0,4.34,2.68c1.75.59,4.1,1.26,7.05,2a64,64,0,0,1,8.41,2.55,13.94,13.94,0,0,1,5.49,3.79,10.07,10.07,0,0,1,2.25,6.88,11.66,11.66,0,0,1-2,6.54,12.91,12.91,0,0,1-5.95,4.68A24.36,24.36,0,0,1,604.9,458.82Z'
            variants={appear}
            custom={10}
          />
          <motion.path
            className='svg-text'
            d='M64.8,368.16a8.59,8.59,0,0,1,5.73,1.76,5.86,5.86,0,0,1,2.12,4.79,6,6,0,0,1-2.12,4.83,8.69,8.69,0,0,1-5.73,1.72H59.67v6h-2V368.16Zm0,11.34a6.63,6.63,0,0,0,4.35-1.25,4.35,4.35,0,0,0,1.51-3.54,4.3,4.3,0,0,0-1.51-3.54,6.69,6.69,0,0,0-4.35-1.25H59.67v9.58Z'
            variants={appear}
            custom={8}
          />
          <motion.path
            className='svg-text'
            d='M106.46,385.53v1.77H92.92V368.16h13.15v1.76H95v6.82h9.91v1.72H95v7.07Z'
            variants={appear}
            custom={8}
          />
          <motion.path
            className='svg-text'
            d='M135.18,387.47a10.42,10.42,0,0,1-5.13-1.25,9.38,9.38,0,0,1-3.58-3.49,9.83,9.83,0,0,1-1.29-5,9.66,9.66,0,0,1,1.29-5,9.1,9.1,0,0,1,3.58-3.49,10.88,10.88,0,0,1,9.1-.56,7.92,7.92,0,0,1,3.06,2.07l-1.3,1.29a7.61,7.61,0,0,0-5.65-2.29,8.25,8.25,0,0,0-4.13,1,7.74,7.74,0,0,0-2.94,2.85,8.48,8.48,0,0,0,0,8.1,7.74,7.74,0,0,0,2.94,2.85,8.25,8.25,0,0,0,4.13,1,7.46,7.46,0,0,0,5.65-2.28l1.3,1.29a8.57,8.57,0,0,1-3.11,2.07A10.16,10.16,0,0,1,135.18,387.47Z'
            variants={appear}
            custom={8}
          />
          <motion.path
            className='svg-text'
            d='M174.19,382.17H163.5l-2.29,5.13H159.1l8.75-19.14h2l8.75,19.14h-2.11Zm-.78-1.64-4.57-10.26-4.61,10.26Z'
            variants={appear}
            custom={8}
          />
          <motion.path
            className='svg-text'
            d='M202.71,387.47a11.83,11.83,0,0,1-4.09-.69,7.33,7.33,0,0,1-3-1.81l.77-1.55a7.6,7.6,0,0,0,2.76,1.68,10.13,10.13,0,0,0,3.58.65,6.49,6.49,0,0,0,3.84-.95,2.92,2.92,0,0,0,1.29-2.46,2.37,2.37,0,0,0-.73-1.81,4.48,4.48,0,0,0-1.68-1,23.22,23.22,0,0,0-2.8-.82,32.34,32.34,0,0,1-3.45-1,5.55,5.55,0,0,1-2.2-1.55,4.22,4.22,0,0,1-.91-2.85,4.91,4.91,0,0,1,.74-2.63,5.14,5.14,0,0,1,2.37-1.89,9.29,9.29,0,0,1,3.92-.74,11.05,11.05,0,0,1,3.19.48,9,9,0,0,1,2.76,1.25l-.69,1.59a9.88,9.88,0,0,0-2.59-1.16,8.92,8.92,0,0,0-2.67-.43,6.14,6.14,0,0,0-3.79,1,3,3,0,0,0-1.25,2.5,2.51,2.51,0,0,0,.69,1.85,4.55,4.55,0,0,0,1.72,1.08q1.07.36,2.85.78a26.58,26.58,0,0,1,3.4,1,5.75,5.75,0,0,1,2.2,1.56,4,4,0,0,1,.91,2.76,4.76,4.76,0,0,1-.78,2.63,5.54,5.54,0,0,1-2.41,1.89A9.77,9.77,0,0,1,202.71,387.47Z'
            variants={appear}
            custom={8}
          />
          <motion.path
            className='svg-text'
            d='M235.18,387.47a11.89,11.89,0,0,1-4.1-.69,7.39,7.39,0,0,1-3-1.81l.78-1.55a7.6,7.6,0,0,0,2.76,1.68,10.09,10.09,0,0,0,3.58.65,6.46,6.46,0,0,0,3.83-.95,2.9,2.9,0,0,0,1.3-2.46,2.41,2.41,0,0,0-.73-1.81,4.61,4.61,0,0,0-1.69-1,22.15,22.15,0,0,0-2.8-.82,33.08,33.08,0,0,1-3.45-1,5.69,5.69,0,0,1-2.2-1.55,4.27,4.27,0,0,1-.9-2.85,4.9,4.9,0,0,1,.73-2.63,5.19,5.19,0,0,1,2.37-1.89,9.34,9.34,0,0,1,3.93-.74,11.16,11.16,0,0,1,3.19.48,9.08,9.08,0,0,1,2.76,1.25l-.69,1.59a10,10,0,0,0-2.59-1.16,9,9,0,0,0-2.67-.43,6.16,6.16,0,0,0-3.8,1,3,3,0,0,0-1.25,2.5,2.55,2.55,0,0,0,.69,1.85,4.59,4.59,0,0,0,1.73,1.08c.71.24,1.66.5,2.84.78a27.31,27.31,0,0,1,3.41,1,5.83,5.83,0,0,1,2.2,1.56,4,4,0,0,1,.9,2.76,4.83,4.83,0,0,1-.77,2.63,5.51,5.51,0,0,1-2.42,1.89A9.72,9.72,0,0,1,235.18,387.47Z'
            variants={appear}
            custom={8}
          />
          <motion.path
            className='svg-text'
            d='M270.7,387.47a10.69,10.69,0,0,1-5.17-1.25,9.38,9.38,0,0,1-3.58-3.49,9.83,9.83,0,0,1-1.29-5,9.66,9.66,0,0,1,1.29-5,9.1,9.1,0,0,1,3.58-3.49,10.5,10.5,0,0,1,5.17-1.3,10.27,10.27,0,0,1,5.13,1.3,9.17,9.17,0,0,1,3.58,3.49,9.41,9.41,0,0,1,1.34,5,9.58,9.58,0,0,1-1.34,5,9.45,9.45,0,0,1-3.58,3.49A10.45,10.45,0,0,1,270.7,387.47Zm0-1.81a8.13,8.13,0,0,0,4.1-1,7.47,7.47,0,0,0,2.89-2.85,8.48,8.48,0,0,0,0-8.1,7.47,7.47,0,0,0-2.89-2.85,8.14,8.14,0,0,0-4.1-1,8.24,8.24,0,0,0-4.09,1,7.64,7.64,0,0,0-2.93,2.85,8.41,8.41,0,0,0,0,8.1,7.64,7.64,0,0,0,2.93,2.85A8.23,8.23,0,0,0,270.7,385.66Z'
            variants={appear}
            custom={8}
          />
          <motion.path
            className='svg-text'
            d='M308.64,387.47a7.87,7.87,0,0,1-5.83-2.11q-2.06-2.14-2.06-6.25V368.16h2v10.9a7.06,7.06,0,0,0,1.51,5,5.6,5.6,0,0,0,4.36,1.64A5.53,5.53,0,0,0,313,384a7.11,7.11,0,0,0,1.51-5v-10.9h2v10.95q0,4.11-2.07,6.25A7.71,7.71,0,0,1,308.64,387.47Z'
            variants={appear}
            custom={8}
          />
          <motion.path
            className='svg-text'
            d='M359.58,379.06h7.16v1.69h-7.16Z'
            variants={appear}
            custom={8}
          />
          <motion.path
            className='svg-text'
            d='M410,368.16h2v17.37h10.73v1.77H410Z'
            variants={appear}
            custom={8}
          />
          <motion.path
            className='svg-text'
            d='M450.1,387.47a10.66,10.66,0,0,1-5.17-1.25,9.38,9.38,0,0,1-3.58-3.49,9.83,9.83,0,0,1-1.29-5,9.66,9.66,0,0,1,1.29-5,9.1,9.1,0,0,1,3.58-3.49,10.47,10.47,0,0,1,5.17-1.3,10.27,10.27,0,0,1,5.13,1.3,9.17,9.17,0,0,1,3.58,3.49,9.5,9.5,0,0,1,1.34,5,9.67,9.67,0,0,1-1.34,5,9.45,9.45,0,0,1-3.58,3.49A10.45,10.45,0,0,1,450.1,387.47Zm0-1.81a8.13,8.13,0,0,0,4.1-1,7.47,7.47,0,0,0,2.89-2.85,8.48,8.48,0,0,0,0-8.1,7.47,7.47,0,0,0-2.89-2.85,8.14,8.14,0,0,0-4.1-1,8.25,8.25,0,0,0-4.1,1,7.71,7.71,0,0,0-2.93,2.85,8.48,8.48,0,0,0,0,8.1,7.71,7.71,0,0,0,2.93,2.85A8.24,8.24,0,0,0,450.1,385.66Z'
            variants={appear}
            custom={8}
          />
          <motion.path
            className='svg-text'
            d='M493.81,377.73h1.94V385a9.19,9.19,0,0,1-3.19,1.86,11.88,11.88,0,0,1-3.84.6,10.49,10.49,0,0,1-5.17-1.25,9.63,9.63,0,0,1-3.62-3.49,9.74,9.74,0,0,1-1.3-5,9.57,9.57,0,0,1,1.3-5,9.34,9.34,0,0,1,3.62-3.49,11.06,11.06,0,0,1,9.18-.56,8.22,8.22,0,0,1,3.11,2l-1.25,1.3a7.87,7.87,0,0,0-5.78-2.25,8.55,8.55,0,0,0-4.18,1,7.9,7.9,0,0,0,0,13.8,9.16,9.16,0,0,0,9.18-.43Z'
            variants={appear}
            custom={8}
          />
          <motion.path
            className='svg-text'
            d='M530.63,385.53v1.77H517.09V368.16h13.15v1.76H519.12v6.82H529v1.72h-9.91v7.07Z'
            variants={appear}
            custom={8}
          />
          <motion.path
            className='svg-text'
            d='M563.06,382.17H552.37l-2.29,5.13H548l8.75-19.14h2l8.75,19.14h-2.12Zm-.78-1.64-4.57-10.26-4.61,10.26Z'
            variants={appear}
            custom={8}
          />
          <motion.path
            className='svg-text'
            d='M586.28,368.16h2V387.3h-2Z'
            variants={appear}
            custom={8}
          />
          <motion.path
            className='svg-text'
            d='M615.51,387.47a11.85,11.85,0,0,1-4.1-.69,7.39,7.39,0,0,1-3-1.81l.78-1.55a7.52,7.52,0,0,0,2.76,1.68,10.09,10.09,0,0,0,3.58.65,6.46,6.46,0,0,0,3.83-.95,2.93,2.93,0,0,0,1.3-2.46,2.38,2.38,0,0,0-.74-1.81,4.48,4.48,0,0,0-1.68-1,22.67,22.67,0,0,0-2.8-.82,32.34,32.34,0,0,1-3.45-1,5.62,5.62,0,0,1-2.2-1.55,4.27,4.27,0,0,1-.9-2.85,4.9,4.9,0,0,1,.73-2.63,5.19,5.19,0,0,1,2.37-1.89,9.33,9.33,0,0,1,3.92-.74,11.05,11.05,0,0,1,3.19.48,8.86,8.86,0,0,1,2.76,1.25l-.69,1.59a10,10,0,0,0-2.58-1.16,9,9,0,0,0-2.68-.43,6.14,6.14,0,0,0-3.79,1,3,3,0,0,0-1.25,2.5,2.55,2.55,0,0,0,.69,1.85,4.55,4.55,0,0,0,1.72,1.08q1.07.36,2.85.78a26.58,26.58,0,0,1,3.4,1,5.67,5.67,0,0,1,2.2,1.56,3.91,3.91,0,0,1,.91,2.76,4.76,4.76,0,0,1-.78,2.63,5.42,5.42,0,0,1-2.41,1.89A9.72,9.72,0,0,1,615.51,387.47Z'
            variants={appear}
            custom={8}
          />
          <motion.path
            className='svg-pla'
            d='M137.3,227.17,63.06,63.39h98.77c45.26,0,81.89,37.86,81.89,84.66a81.85,81.85,0,0,1-81.89,81.89H63.06V338.59'
            variants={draw}
          />
          <motion.path
            className='svg-pla'
            d='M249.26,63.39v275.2H364.77L492.16,63.39l125,275.2H431.23'
            variants={draw}
            custom={2}
          />
        </motion.svg>
      </div>
      <div className='second-div'>test</div>
    </>
  )
}

export default Homepage
