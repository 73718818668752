import React, { useEffect, useState } from 'react'
import { Link, useNavigate, useLocation } from 'react-router-dom'
import '../../../assets/css/layout/navigation.css'
import { motion } from 'framer-motion'
import Logo from '../Logo/'

function Navigation() {
  useEffect(() => {
    const nav = document.querySelector('#nav')
    let previousScrollY = 0

    const handleScroll = () => {
      if (
        window.scrollY > 70 &&
        previousScrollY <= window.scrollY &&
        window.innerWidth > 1000
      ) {
        nav.classList.add('bg-color')
        nav.classList.remove('bg-color-start')
      } else if (
        window.scrollY <= 70 &&
        previousScrollY > window.scrollY &&
        window.innerWidth > 1000
      ) {
        nav.classList.remove('bg-color')
        nav.classList.add('bg-color-start')
      }
      previousScrollY = window.scrollY
    }

    window.addEventListener('scroll', handleScroll)

    return () => {
      window.removeEventListener('scroll', handleScroll)
    }
  }, [])
  const hideMenu = () => {
    if (window.innerWidth < 1000) {
      const menuHamb = document.getElementsByClassName('hamburger')
      const menuNav = document.getElementById('nav')
      /* const mItem = document.getElementsByClassName('nav-item-deco-b')[n] */

      menuHamb[0].classList.remove('is-active')
      menuNav.style.transitionDelay = '0.2s'
      menuNav.style.left = '100%'
      /* mItem.style.left = '100%' */
    }
  }
  const navigate = useNavigate()
  function delayRedirect(e, path) {
    if (window.innerWidth < 1000) {
      e.preventDefault()
      hideMenu()
      setTimeout(() => navigate(path), 1300)
    }
  }
  //framer.motion
  const location = useLocation()
  const [selectedTab, setSelectedTab] = useState(location.pathname)
  return (
    <>
      {location.pathname !== '/' ? <Logo /> : null}
      <div id='nav' className='bg-color-start'>
        <ul className='nav-list'>
          <li
            key='/'
            className={'/' === selectedTab ? 'selected' : ''}
            onClick={() => setSelectedTab('/')}
          >
            <Link to='/' onClick={(e) => delayRedirect(e, '/')}>
              <button className='nav-item'>ACCUEIL</button>
            </Link>
            {selectedTab === '/' ? (
              <motion.div className='underline' layoutId='underline' />
            ) : null}
          </li>
          <li
            key='/le-cabinet'
            className={'/le-cabinet' === selectedTab ? 'selected' : ''}
            onClick={() => setSelectedTab('/le-cabinet')}
          >
            <Link
              to='le-cabinet'
              onClick={(e) => delayRedirect(e, 'le-cabinet')}
            >
              <button className='nav-item'>LE CABINET</button>
            </Link>
            {selectedTab === '/le-cabinet' ? (
              <motion.div className='underline' layoutId='underline' />
            ) : null}
          </li>
          <li
            key='/l-equipe'
            className={'/l-equipe' === selectedTab ? 'selected' : ''}
            onClick={() => setSelectedTab('/l-equipe')}
          >
            <Link to='l-equipe' onClick={(e) => delayRedirect(e, 'l-equipe')}>
              <button className='nav-item'>L'ÉQUIPE</button>
            </Link>
            {selectedTab === '/l-equipe' ? (
              <motion.div className='underline' layoutId='underline' />
            ) : null}
          </li>
          <li
            key='/nos-expertises'
            className={'/nos-expertises' === selectedTab ? 'selected' : ''}
            onClick={() => setSelectedTab('/nos-expertises')}
          >
            <Link
              to='nos-expertises'
              onClick={(e) => delayRedirect(e, 'nos-expertises')}
            >
              <button className='nav-item'>NOS EXPERTISES</button>
            </Link>
            {selectedTab === '/nos-expertises' ? (
              <motion.div className='underline' layoutId='underline' />
            ) : null}
          </li>
          <li
            key='/actualites'
            className={'/actualites' === selectedTab ? 'selected' : ''}
            onClick={() => setSelectedTab('/actualites')}
          >
            <Link
              to='actualites'
              onClick={(e) => delayRedirect(e, 'actualites')}
            >
              <button className='nav-item'>ACTUALITÉS</button>
            </Link>
            {selectedTab === '/actualites' ? (
              <motion.div className='underline' layoutId='underline' />
            ) : null}
          </li>
          <li
            key='/contact'
            className={'/contact' === selectedTab ? 'selected' : ''}
            onClick={() => setSelectedTab('/contact')}
          >
            <Link to='contact' onClick={(e) => delayRedirect(e, 'contact')}>
              <button className='nav-item'>CONTACT</button>
            </Link>
            {selectedTab === '/contact' ? (
              <motion.div className='underline' layoutId='underline' />
            ) : null}
          </li>
        </ul>
      </div>
    </>
  )
}

export default Navigation
