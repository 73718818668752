import React from 'react'
import '../../assets/css/pages/cabinet.css'

function Cabinet() {
  return (
    <>
      <div className='cabinet-section1'>Cabinet</div>
      <div className='cabinet-section2'>Cabinet</div>
    </>
  )
}

export default Cabinet
