import React from 'react'
import '../../assets/css/pages/expertises.css'

function Expertises() {
  return (
    <>
      <div className='expertises-section1'>Expertises</div>
      <div className='expertises-section2'>Expertises</div>
    </>
  )
}

export default Expertises
