import React from 'react'
import ReactDOM from 'react-dom/client'
import './index.css'
import reportWebVitals from './reportWebVitals'
import {
  Route,
  createBrowserRouter,
  createRoutesFromElements,
  RouterProvider,
} from 'react-router-dom'
import AnimatedRoutes from './components/AnimatedRoutes'
import { Provider } from 'react-redux'
import thunk from 'redux-thunk'
import reducers from './reducers'
import {
  legacy_createStore as createStore,
  applyMiddleware,
  compose,
} from 'redux'

const store = createStore(reducers, compose(applyMiddleware(thunk)))
const router = createBrowserRouter(
  createRoutesFromElements(<Route path='*' element={<AnimatedRoutes />} />),
  { basename: '/' }
)
ReactDOM.createRoot(document.getElementById('root')).render(
  <Provider store={store}>
    <React.StrictMode>
      <RouterProvider router={router} />
    </React.StrictMode>
  </Provider>
)

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals()
reportWebVitals(console.log)
