import React from 'react'
import { Link } from 'react-router-dom'
import '../../assets/css/components/cardarticle.css'

function CardArticle({ article }) {
  let date = article.id
  date = date.replaceAll('_', ' ')
  const regex = /\s.$/gm
  if (date.match(regex)) {
    date = date.substring(0, date.length - 2)
  }
  let link = article.name
  link = link.replaceAll(' ', '-')
  const handleClickScroll = () => {
    const element = document.getElementById('article')
    if (element) {
      // 👇 Will scroll smoothly to the top of the next section
      element.scrollIntoView({ behavior: 'smooth' })
    }
  }
  return (
    <Link
      to={`${article.id}/${link}`}
      preventScrollReset={true}
      onClick={handleClickScroll}
    >
      <div className='card'>
        <div className='card-image-container'>
          <img
            className='card-image'
            src={`data:image/jpeg;base64,${article.image}`}
            alt='lol'
          />
        </div>
        <div className='card-date'>{date}</div>
        <div className='card-title'>{article.name}</div>
        <div className='card-desc'>{article.description}</div>
      </div>
    </Link>
  )
}

export default CardArticle
