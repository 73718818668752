import React from 'react'
import { Outlet, ScrollRestoration } from 'react-router-dom'
import Header from './layout/Header'
import Footer from './layout/Footer'
import { motion } from 'framer-motion'

import './Layout.css'

function Layout() {
  return (
    <>
      <div className='Layout'>
        <Header />
        <motion.div
          initial='initial'
          animate='in'
          exit='out'
          variants={{
            initial: {
              opacity: 0,
            },
            in: {
              opacity: 1,
            },
            out: {
              opacity: 0,
            },
          }}
          transition={{
            type: 'spring',
            damping: 5,
            stiffness: 22,
          }}
        >
          <Outlet />
        </motion.div>
      </div>
      <Footer />
      <ScrollRestoration />
    </>
  )
}

export default Layout
